// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-settings-js": () => import("./../../../src/pages/account-settings.js" /* webpackChunkName: "component---src-pages-account-settings-js" */),
  "component---src-pages-add-thread-js": () => import("./../../../src/pages/add-thread.js" /* webpackChunkName: "component---src-pages-add-thread-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-change-email-js": () => import("./../../../src/pages/change-email.js" /* webpackChunkName: "component---src-pages-change-email-js" */),
  "component---src-pages-edit-profile-js": () => import("./../../../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-most-trending-js": () => import("./../../../src/pages/most-trending.js" /* webpackChunkName: "component---src-pages-most-trending-js" */),
  "component---src-pages-mym-js": () => import("./../../../src/pages/mym.js" /* webpackChunkName: "component---src-pages-mym-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-link-sent-js": () => import("./../../../src/pages/reset-link-sent.js" /* webpackChunkName: "component---src-pages-reset-link-sent-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-reset-password-success-js": () => import("./../../../src/pages/reset-password-success.js" /* webpackChunkName: "component---src-pages-reset-password-success-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thread-js": () => import("./../../../src/pages/thread.js" /* webpackChunkName: "component---src-pages-thread-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

