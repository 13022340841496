import { httpClient as client, getToken } from "./client";

export function getRestCountries() {
  return new Promise((resolve, reject) => {
    client
      .get("https://api.cerebropro.com/api/countries")
      .then(res => {
        resolve(res)
      })
      .catch(err => reject(err))
  })
}

export function getMe() {
  return new Promise((resolve, reject) => {
    client
      .get(`me`,
        {
          headers: {Authorization: `Bearer ${getToken()}`}
        }
      )
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}

export function register(data) {
  return new Promise((resolve, reject) => {
    client
      .post("register", data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function login(data) {
  return new Promise((resolve, reject) => {
    client
      .post("login", data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    client
      .post("password/forgot", {email})
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function resetPassword(data) {
  return new Promise((resolve, reject) => {
    client
      .post("password/reset", data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function editProfile(data) {
  return new Promise((resolve, reject) => {
    client
      .post(
        "editProfile", data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'content-type': 'multipart/form-data',
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function changePassword(data) {
  return new Promise((resolve, reject) => {
    client
      .post(
        "changePassword", data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function requestChangeEmail(newEmail) {
  return new Promise((resolve, reject) => {
    client
      .post(
        "requestChangeEmail",
        {
          new_email: newEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function confirmChangeEmail(token) {
  return new Promise((resolve, reject) => {
    client
      .post(
        "confirmChangeEmail",
        {token},
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function cancelChangeEmail() {
  return new Promise((resolve, reject) => {
    client
      .post(
        "cancelChangeEmail",
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}