import React, { useState, useEffect, useRef } from "react";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import { Modal } from "react-bootstrap";
import Image from "gatsby-image";
import moment from 'moment-timezone';
import PropTypes from "prop-types";
import { isMobile } from "./helper";
import {getToken, removeToken, removeCountry} from "@repository/client";

import {getMe, getRestCountries} from "../http/UserRepository";
import {getNotifications} from "../http/NotificationRepository";
import DefaultProfilePicture from '@backgrounds/cerebro_default_pp.png';
import {getThreadCategory} from '@repository/ThreadCategoryRepository';
import "../styles/_header.scss";
import { check } from "prettier";

function Header({ siteTitle }) {
  const images = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "icons/cerebro-ic.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const isLogined = getToken() ? true : false;

  const searchbar = useRef(null)

  // STATES
  const [user, setUser] = useState(null);
  const [isExpandSearchbar, setIsExpandSearchbar] = useState(false)
  const [isExpandNavigation, setIsExpandNavigation] = useState(false)
  const [isSelectingCountry, setIsSelectingCountry] = useState(false)
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  const [isExpandCategory, setIsExpandCategory] = useState(false)
  const [isShowAccounts, setIsShowAccounts] = useState(false)
  const [isShowNotification, setIsShowNotificisShowNotification] = useState(false)
  const [countries, setCountries] = useState(null)
  const [countriesMix, setCountriesMix] = useState(null)
  const [countryKeyword, setCountryKeyword] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [topic, setTopic] = useState(null)
  const [categories, setCategories] = useState([])
  const [searchText, setSearchText] = useState("");
  const [notifications, setNotifications] = useState([]);
  // const [categories, setCategories] = useState([]);

  useEffect(() => {
    handleGetCategories();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isLogined) {
        handleGetNotifications();
      }
    }, 60 * 1000);
    if (isLogined) {
      handleGetNotifications();
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isLogined]);

  useEffect(() => {
    if (isLogined) {
      handleGetUser();

      if (countriesMix !== undefined && countriesMix !== null && countriesMix.length > 0) {
        let localStorageCountryString = localStorage.getItem('country');
        if (localStorageCountryString !== null) {
          let localStorageCountryJSON = JSON.parse(localStorageCountryString);
          setSelectedCountry(localStorageCountryJSON.name);
        } else {
          // setSelectedCountry("Indonesia");
        }
      }
    }
  }, [isLogined]);

  useEffect(() => {
    if (countries === null || countries === undefined) {
      return;
    }
    if (selectedCountry !== undefined && selectedCountry !== null && selectedCountry.trim() !== '') {
      for (const key in countries) {
        let _country = countries[key].find((c) => c.name === selectedCountry);
        if (_country !== undefined) {
          
          let localStorageCountryString = localStorage.getItem('country');
          if (localStorageCountryString !== null) {
            let localStorageCountryJSON = JSON.parse(localStorageCountryString);
            let localStorageCountryName = localStorageCountryJSON.name;
            if (localStorageCountryName !== selectedCountry) {
              localStorage.setItem('country', JSON.stringify(_country));   
              window.location.reload();
            }
          } else {
            localStorage.setItem('country', JSON.stringify(_country));   
            window.location.reload();
          }
          return;
        }
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (countriesMix !== undefined && countriesMix !== null && countriesMix.length > 0) {
      let localStorageCountryString = localStorage.getItem('country');
      if (localStorageCountryString !== null) {
        let localStorageCountryJSON = JSON.parse(localStorageCountryString);
        setSelectedCountry(localStorageCountryJSON.name);
      } else {
        // setSelectedCountry("Indonesia");
      }
    }
  }, [countriesMix]);

  const handleGetUser = () => {
    getMe()
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  }

  const handleGetCategories = () => {
    getThreadCategory()
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleGetNotifications = () => {
    getNotifications()
      .then((res) => {
        setNotifications(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  // CUSTOM FUNCTIONS
  const handleGetRestCountries = () => {
    getRestCountries()
      .then(res => {
        setCountriesMix(res.data)
        setCountries(prevState => {
          return {
            asia: res.data.filter(checkIsAsia),
            americas: res.data.filter(checkIsAmericas),
            africa: res.data.filter(checkIsAfrica),
            europe: res.data.filter(checkIsEurope),
            oceania: res.data.filter(checkIsOceania),
            others: res.data.filter(checkIsOthers),
          }
        })
      })
      .catch(err => console.log(err))
  }

  const checkIsAsia = countries => {
    return countries.region === "Asia"
  }

  const checkIsAmericas = countries => {
    return countries.region === "Americas"
  }

  const checkIsAfrica = countries => {
    return countries.region === "Africa"
  }

  const checkIsEurope = countries => {
    return countries.region === "Europe"
  }

  const checkIsOceania = countries => {
    return countries.region === "Oceania"
  }

  const checkIsOthers = countries => {
    return (
      countries.region !== "Oceania" &&
      countries.region !== "Europe" &&
      countries.region !== "Africa" &&
      countries.region !== "Americas" &&
      countries.region !== "Asia"
    )
  }

  useEffect(() => {
    handleGetRestCountries()
  }, [])

  useEffect(() => {
    if (isExpandNavigation || isSelectingCountry) {
      document.body.style.overflow = "hidden"
      document.body.style.position = "fixed"
      document.body.style.width = "100%"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.position = "unset"
      document.body.style.width = "100%"
    }
  }, [isExpandNavigation, isSelectingCountry])

  const selectCountry = () => {
    return (
      <Modal
        show={isSelectingCountry}
        onHide={() => setIsSelectingCountry(false)}
        size="xl"
        className="country-modal"
        centered
        // backdrop="static"
      >
        <Modal.Body>
          <h2 className="dosis-title-1 text-philadelphia-blue text-center mb-4">
            Choose Country
          </h2>
          <span
            className="mdi mdi-close mdi-24px"
            onClick={() => {
              setIsExpandSearchbar(false)
              setIsSelectingCountry(false)
              setIsSearchFocus(false)
            }}
          />
          <input
            type="text"
            className="form-control mb-4"
            placeholder="Search country"
            value={countryKeyword}
            onChange={e => {
              setCountryKeyword(e.target.value)
            }}
          />
          {countryKeyword ? (
            <div className="row">
              {countries &&
                countriesMix.map(country => {
                  if (
                    country.name
                      .toLowerCase()
                      .includes(countryKeyword.toLowerCase())
                  ) {
                    return (
                      <div
                        className="col-3 mb-2 country-option"
                        key={country._id}
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10 px-0">{country.name}</div>
                        </div>
                      </div>
                    )
                  }
                })}
            </div>
          ) : (
            <>
              <h2 className="title-3 text-steel-gray">Africa</h2>
              <div className="row">
                {countries &&
                  countries.africa.map(country => {
                    return (
                      <div
                        className="col-3 mb-2 country-option"
                        key={country._id}
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10 px-0">{country.name}</div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <h2 className="title-3 text-steel-gray mt-5">America</h2>
              <div className="row">
                {countries &&
                  countries.americas.map(country => {
                    return (
                      <div
                        className="col-3 mb-2 country-option"
                        key={country._id}
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10 px-0">{country.name}</div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <h2 className="title-3 text-steel-gray mt-5">Asia</h2>
              <div className="row">
                {countries &&
                  countries.asia.map(country => {
                    return (
                      <div
                        className="col-3 mb-2 country-option"
                        key={country._id}
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10 px-0">{country.name}</div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <h2 className="title-3 text-steel-gray mt-5">Europe</h2>
              <div className="row">
                {countries &&
                  countries.europe.map(country => {
                    return (
                      <div
                        className="col-3 mb-2 country-option"
                        key={country._id}
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10 px-0">{country.name}</div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <h2 className="title-3 text-steel-gray mt-5">Oceania</h2>
              <div className="row">
                {countries &&
                  countries.oceania.map(country => {
                    return (
                      <div
                        className="col-3 mb-2 country-option"
                        key={country._id}
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10 px-0">{country.name}</div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <h2 className="title-3 text-steel-gray mt-5">Others</h2>
              <div className="row">
                {countries &&
                  countries.others.map(country => {
                    return (
                      <div
                        className="col-3 mb-2 country-option"
                        key={country._id}
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10 px-0">{country.name}</div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    )
  }

  const handleSearch = (event) => {
    if (event.keyCode === 13 && searchText.trim() !== "") {
      event.preventDefault();
      setIsSearchFocus(false);
      navigate(`/search?searchterm=${searchText}`)
      setSearchText('');
    }
  }

  const isOverlay = isExpandCategory || isSearchFocus || isShowAccounts || isShowNotification;
  if (!isMobile()) {
    return (
      <header>
        {selectCountry()}
        <div
          className={`${isOverlay && "expand"} overlay`}
          onClick={() => {
            setIsExpandCategory(false)
            setIsSearchFocus(false)
            setIsShowAccounts(false)
          }}
        />
        <div id="navbar">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-md-2 my-auto">
                <div className="cursor-pointer" onClick={() => navigate('/')}>
                  <Image
                    className="company-logo"
                    fluid={images.logo.childImageSharp.fluid}
                    alt="Cerebro"
                  />
                </div>
              </div>
              <div className={`my-auto ${(user && user.country.name && user.country.name === selectedCountry) ? "col-md-4": "col-md-5"}`}>
                <div className="searchbar w-100">
                  <input
                    type="text"
                    className="form-control"
                    id="browse"
                    placeholder="Search threads..."
                    onFocus={() => setIsSearchFocus(true)}
                    onKeyUp={handleSearch}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <div className={`my-auto ${(user && user.country.name && user.country.name === selectedCountry) ? "col-md-6" : "col-md-5"}`}>
                <div className="navbar-nav">
                  <ul className="list-unstyled list-inline mb-0 d-flex align-items-center justify-content-around">
                    <li className="list-inline-item navbar-nav-item text-steel-gray">
                      <Link to="/browse">Browse</Link>
                    </li>
                    {!isLogined ? (
                      <>
                        <li className="list-inline-item navbar-nav-item">
                          <Link to="/login">Login</Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to="/register"
                            className="button button-primary button-sm"
                          >
                            Register
                          </Link>
                        </li>
                        <li
                          className={`cursor-pointer list-inline-item border-left pl-4 ${
                            selectedCountry && "w-25"
                          }`}
                        >
                          <span
                            onClick={() => {
                              setIsSelectingCountry(true)
                              setIsExpandNavigation(false)
                            }}
                          >
                            {selectedCountry &&
                              countriesMix.map(country => {
                                if (
                                  country.name.toLowerCase() ===
                                  selectedCountry.toLowerCase()
                                ) {
                                  return (
                                    <>
                                      <img
                                        src={country.flag}
                                        alt="flag"
                                        width="30%"
                                        className="mr-2"
                                      />
                                      {country.alpha3Code}{" "}
                                      <i className="mdi mdi-menu-down mdi-18px" />
                                    </>
                                  )
                                }
                              })}
                            {!selectedCountry && (
                              <span>
                                Pick country{" "}
                                <i className="mdi mdi-menu-down mdi-18px" />
                              </span>
                            )}
                          </span>
                        </li>
                      </>
                    ) : (
                      <>
                        {(user && user.country.name && user.country.name === selectedCountry) && (
                          <li className="list-inline-item navbar-nav-item text-steel-gray">
                            <Link to="/add-thread">
                              <span className="mdi mdi-circle-edit-outline mdi-18px mr-1 text-steel-gray" />
                              New Thread
                            </Link>
                          </li>
                        )}
                        <li
                          className="list-inline-item navbar-nav-item-account position-relative"
                          onClick={() => {
                            // setIsShowAccounts(true);
                          }}>
                          {user ? (
                            <div
                              className="d-flex align-items-center cursor-pointer"
                              id="account-menu"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <div
                                className="position-relative d-flex rounded-circle profile-picture mr-2"
                                style={{
                                  backgroundImage: `url(${user.profile_picture_url ? user.profile_picture_url : DefaultProfilePicture})`
                                }} />
                              <div>
                                <div className="asap-body text-azure-blue user-email">{user.email}</div>
                                <div className="asap-caption text-steel-gray user-fullname">{user.fullname}</div>
                              </div>
                              <i className="mdi mdi-menu-down mdi-18px ml-2" />
                            </div>
                          ) : (
                            <div className="mdi mdi-loading mdi-spin mdi-24px m-auto" />
                          )}
                          <div className="dropdown-menu dropdown-menu-account w-100" aria-labelledby="account-menu">
                            <div className="dropdown-item py-2 cursor-pointer asap-body text-iron-gray" onClick={() => navigate('/edit-profile')}>
                              Edit Profile
                            </div>
                            <div className="dropdown-item py-2 cursor-pointer asap-body text-iron-gray" onClick={() => navigate('/account-settings')}>
                              Account Settings
                            </div>
                            <div
                              className="dropdown-item py-2 cursor-pointer"
                              onClick={() => {
                                setIsSelectingCountry(true)
                                setIsExpandNavigation(false)
                              }}
                            >
                              <div className="asap-body text-iron-gray">Switch Country</div>
                              {selectedCountry &&
                                countriesMix.map(country => {
                                  if (
                                    country.name.toLowerCase() ===
                                    selectedCountry.toLowerCase()
                                  ) {
                                    return (
                                      <div className="d-flex align-items-center text-harbor-gray" key={country.name}>
                                        <div className="asap-caption">Current : {country.alpha3Code}</div>
                                        <img
                                          src={country.flag}
                                          alt="flag"
                                          width="10%"
                                          className="ml-2"
                                        />
                                      </div>
                                    )
                                  }
                                })}
                            </div>
                            <div
                              className="dropdown-item py-2 cursor-pointer asap-body text-apple-red"
                              onClick={() => {
                                removeToken();
                                // removeCountry();
                                navigate('/');
                                window.location.reload();
                              }}>
                                Logout
                            </div>
                          </div>
                          <div className="account-overlay" />
                        </li>
                        <div className="navbar-nav-divider" />
                        <li className="list-inline-item cursor-pointer"> {/* navbar-nav-item navbar-nav-item-bell */}
                          <div
                            id="notification-menu"
                            data-toggle="dropdown"
                            className="position-relative"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <span className="mdi mdi-bell mdi-24px text-abalone-gray" />
                            {notifications.length > 0 && <div className="red-dot" />}
                          </div>
                          
                          <div className="dropdown-menu dropdown-menu-notification dropdown-menu-right p-3" aria-labelledby="notification-menu">
                            <div className="d-flex align-items-center mb-2">
                              <div className="dosis-headline text-philadelphia-blue">
                                Notifications
                              </div>
                              {notifications.length > 0 && (
                                <div className="ml-2 rounded-circle d-flex align-items-center justify-content-center bg-apple-red notification-badge">
                                  <div className="text-pure-white notification-badge-text">{notifications.length < 100 ? notifications.length : "99+"}</div>
                                </div>
                              )}
                            </div>
                            {notifications.length > 0 ? (
                              <div className="notification-menus-wrapper">
                                {notifications.map((notification) => (
                                  <Link
                                    key={notification._id}
                                    to={`/thread?id=${notification.thread_id}`}
                                    onClick={() => {
                                      setNotifications(
                                        (prevValue) => 
                                          prevValue.filter(
                                            (value) => value._id !== notification._id)
                                      );
                                    }}
                                  >
                                    <div className="bg-pearl-gray p-3 d-flex align-items-center notification-wrapper mb-2">
                                      <div
                                        className="notification-picture rounded-circle mr-2"
                                        style={{
                                          backgroundImage: `url(${notification.user.profile_picture_url ? notification.user.profile_picture_url : DefaultProfilePicture})`
                                        }} />
                                      <div>
                                        <div className="asap-caption">{notification.user.fullname} replied to your {notification.type}.</div>
                                        <div className="asap-caption text-harbor-gray">{moment.unix(notification.created_at).fromNow()}</div>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            ) : (
                              <div className="d-flex align-items-center justify-content-center p-5 no-notification-wrapper">
                                <div className="dosis-title-3 text-abalone-gray text-center">No New Notifications</div>
                              </div>
                            )}
                          </div>
                          <div className="notification-overlay" />
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="navbar-category">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-2 text-center border-right">
                <span
                  onClick={() => {
                    setIsExpandCategory(prevState => !prevState)
                  }}
                  className="cursor-pointer"
                >
                  Category <i className="mdi mdi-menu-down mdi-18px" />
                </span>
              </div> */}
              {categories.slice(0, 6).map((c) => (
                <div className="col-md-2 text-center" key={c._id}>
                  <div onClick={() => navigate(`/browse?categoryid=${c._id}&categorytitle=${c.title}`)}>
                    <Link to="#" className="no-underline">
                      {c.title}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          id="navbar-category-dropdown"
          className={`${isExpandCategory && "expand"} d-flex`}
        >
          <div className="container my-auto">
            <h2 className="asap-headline text-philadelphia-blue">Categories</h2>
            <div className="row">
              {categories.map(c => (
                <div
                  className="col-md-3 my-3"
                  key={c._id}
                  onClick={() => {
                    navigate(`/browse?categoryid=${c._id}&categorytitle=${c.title}`);
                    setIsExpandCategory(prevState => !prevState);
                  }}>
                  <Link to="#" className="no-underline">
                    {c.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </header>
    )
  } else {
    return (
      <div className="m-navbar">
        <div
          className={`${isOverlay && "expand"} overlay`}
          onClick={() => {
            setIsExpandCategory(false)
            setIsShowAccounts(false)
            // setIsSearchFocus(false)
          }}
        />
        <div
          className={`searchbar ${
            (isExpandSearchbar || isSelectingCountry) && "expand"
          }`}
        >
          <div>
            <span className="mdi mdi-magnify mdi-24px" />
            <input
              ref={searchbar}
              type="text"
              className="form-control"
              id="browse"
              placeholder={
                isExpandSearchbar
                  ? `What topic are you looking for?`
                  : "Search country"
              }
              // value={isExpandSearchbar ? topic : countryKeyword}
              onChange={e => {
                if (isExpandSearchbar) {
                  setTopic(e.target.value)
                } else if (isSelectingCountry) {
                  setCountryKeyword(e.target.value)
                }
                setSearchText(e.target.value);
              }}
              onKeyUp={handleSearch}
            />
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() => {
                setIsExpandSearchbar(false)
                setIsSelectingCountry(false)
                setIsSearchFocus(false)
                searchbar.current.value = null
              }}
            />
          </div>
        </div>
        <div className="container h-100 d-flex align-items-center justify-content-between">
          <div className="cursor-pointer" onClick={() => navigate('/')}>
            <Image
              className="company-logo"
              fluid={images.logo.childImageSharp.fluid}
              alt="Cerebro"
            />
          </div>
          <div className="d-flex align-items-center">
              {!isExpandNavigation && (
                <div className="mdi mdi-magnify text-harbor-gray" onClick={() => {
                  setIsExpandSearchbar(true)
                  setIsSearchFocus(true)
                }} />
              )}
              {!isExpandNavigation && (
                <div className="mx-3 position-relative">
                  {user && (
                    <div
                      id="notification-menu"
                      data-toggle="dropdown"
                      className="position-relative"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <span className="mdi mdi-bell mdi-24px text-harbor-gray" />
                      {notifications.length > 0 && <div className="red-dot" />}
                    </div>
                  )}
                  <div className="dropdown-menu dropdown-menu-notification dropdown-menu-notification-mobile dropdown-menu-right p-3" aria-labelledby="notification-menu">
                    <div className="d-flex align-items-center mb-2">
                      <div className="dosis-headline text-philadelphia-blue">
                        Notifications
                      </div>
                      {notifications.length > 0 && (
                        <div className="ml-2 rounded-circle d-flex align-items-center justify-content-center bg-apple-red notification-badge">
                          <div className="text-pure-white notification-badge-text">{notifications.length < 100 ? notifications.length : "99+"}</div>
                        </div>
                      )}
                    </div>
                    {notifications.length > 0 ? (
                      <div className="notification-menus-wrapper">
                        {notifications.map((notification) => (
                          <Link
                            key={notification._id}
                            to={`/thread?id=${notification.thread_id}`}
                            onClick={() => {
                              setNotifications(
                                (prevValue) => 
                                  prevValue.filter(
                                    (value) => value._id !== notification._id)
                              );
                            }}
                          >
                            <div className="bg-pearl-gray p-3 d-flex align-items-center notification-wrapper mb-2">
                              <div
                                className="notification-picture rounded-circle mr-2"
                                style={{
                                  backgroundImage: `url(${notification.user.profile_picture_url ? notification.user.profile_picture_url : DefaultProfilePicture})`
                                }} />
                              <div>
                                <div className="asap-caption">{notification.user.fullname} replied to your {notification.type}.</div>
                                <div className="asap-caption text-harbor-gray">{moment.unix(notification.created_at).fromNow()}</div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center p-5 no-notification-wrapper">
                        <div className="dosis-title-3 text-abalone-gray text-center">No New Notifications</div>
                      </div>
                    )}
                  </div>
                  <div className="notification-overlay" />
                </div>
              )}
              <div
                className={`hamburger-icon ${
                  isExpandNavigation ? "expand" : ""
                }`}
                onClick={() => {
                  setIsExpandNavigation(prevState => !prevState)
                }}
              >
                <span className="hamburger-1" />
                <span className="hamburger-2" />
                <span className="hamburger-3" />
              </div>
          </div>
        </div>
        <div id="navbar-expand" className={isExpandNavigation ? "expand" : ""}>
          <div className="navbar-nav">
            <ul className="list-unstyled">
              <li className="navbar-nav-item">
                <Link className="text-iron-gray" to="/browse" onClick={() => setIsExpandNavigation(prevState => !prevState)}>Browse</Link>
              </li>
              {isLogined ? (
                <>
                  {(user && user.country.name && user.country.name === selectedCountry) && (
                    <li className="navbar-nav-item">
                      <Link className="text-iron-gray" to="/add-thread" onClick={() => setIsExpandNavigation(prevState => !prevState)}>
                        <span className="mdi mdi-circle-edit-outline mdi-18px mr-1 text-iron-gray" /> New Thread
                      </Link>
                    </li>
                  )}
                  <li className="navbar-nav-item">
                    <Link className="text-iron-gray" to="/edit-profile" onClick={() => setIsExpandNavigation(prevState => !prevState)}>Edit Profile</Link>
                  </li>
                  <li className="navbar-nav-item">
                    <Link className="text-iron-gray" to="/account-settings" onClick={() => setIsExpandNavigation(prevState => !prevState)}>Account Settings</Link>
                  </li>  
                </>
              ) : (
                <li className="navbar-nav-item">
                  <Link className="text-iron-gray" to="/login" onClick={() => setIsExpandNavigation(prevState => !prevState)}>Login</Link>
                </li>
              )}
              <li className="navbar-nav-item cursor-pointer pl-4">
                <p className="asap-body text-iron-gray mb-0">Change Country</p>
                <span
                  onClick={() => {
                    setIsSelectingCountry(true)
                    setIsExpandNavigation(false)
                  }}
                >
                  {selectedCountry &&
                    countriesMix.map(country => {
                      if (
                        country.name.toLowerCase() ===
                        selectedCountry.toLowerCase()
                      ) {
                        return (
                          <>
                            <img
                              src={country.flag}
                              alt="flag"
                              width="10%"
                              className="mr-2"
                            />
                            {country.alpha3Code}{" "}
                            <i className="mdi mdi-menu-down mdi-18px" />
                          </>
                        )
                      }
                    })}
                  {!selectedCountry && (
                    <span>
                      Pick country <i className="mdi mdi-menu-down mdi-18px" />
                    </span>
                  )}
                </span>
              </li>
              {isLogined ? (
                <li
                  className="navbar-nav-item border-bottom-none cursor-pointer d-flex align-items-start text-apple-red"
                  onClick={() => {
                    removeToken();
                    // removeCountry();
                    navigate('/');
                    window.location.reload();
                  }}>
                  Logout
                </li>
              ) : (
                <li className="navbar-nav-item border-bottom-none">
                  <Link
                    to="/register"
                    className="button button-primary button-sm btn-block"
                    onClick={() => setIsExpandNavigation(prevState => !prevState)}
                  >
                    Register
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div id="navbar-country" className={isSelectingCountry ? "expand" : ""}>
          {countryKeyword ? (
            <ul className="list-unstyled">
              {countries &&
                countriesMix.map(country => {
                  if (
                    country.name
                      .toLowerCase()
                      .includes(countryKeyword.toLowerCase())
                  ) {
                    return (
                      <li
                        className="py-4 border-bottom"
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsExpandNavigation(true)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10">{country.name}</div>
                        </div>
                      </li>
                    )
                  }
                })}
            </ul>
          ) : (
            <>
              <h3 className="title-3 text-steel-gray">Africa</h3>
              <ul className="list-unstyled">
                {countries &&
                  countries.africa.map(country => {
                    return (
                      <li
                        className="py-4 border-bottom"
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsExpandNavigation(true)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10">{country.name}</div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
              <h3 className="title-3 text-steel-gray mt-5">America</h3>
              <ul className="list-unstyled">
                {countries &&
                  countries.americas.map(country => {
                    return (
                      <li
                        className="py-4 border-bottom"
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsExpandNavigation(true)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10">{country.name}</div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
              <h3 className="title-3 text-steel-gray mt-5">Asia</h3>
              <ul className="list-unstyled">
                {countries &&
                  countries.asia.map(country => {
                    return (
                      <li
                        className="py-4 border-bottom"
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsExpandNavigation(true)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10">{country.name}</div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
              <h3 className="title-3 text-steel-gray mt-5">Europe</h3>
              <ul className="list-unstyled">
                {countries &&
                  countries.europe.map(country => {
                    return (
                      <li
                        className="py-4 border-bottom"
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsExpandNavigation(true)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10">{country.name}</div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
              <h3 className="title-3 text-steel-gray mt-5">Oceania</h3>
              <ul className="list-unstyled">
                {countries &&
                  countries.oceania.map(country => {
                    return (
                      <li
                        className="py-4 border-bottom"
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsExpandNavigation(true)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10">{country.name}</div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
              <h3 className="title-3 text-steel-gray mt-5">Others</h3>
              <ul className="list-unstyled pb-5">
                {countries &&
                  countries.others.map(country => {
                    return (
                      <li
                        className="py-4 border-bottom"
                        onClick={() => {
                          setSelectedCountry(country.name)
                          setCountryKeyword(null)
                          setIsExpandNavigation(true)
                          setIsSelectingCountry(false)
                        }}
                      >
                        <div className="row">
                          <div className="col-2">
                            <img src={country.flag} alt="flag" width="100%" />
                          </div>
                          <div className="col-10">{country.name}</div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </>
          )}
        </div>
        <div id="navbar-category">
          <div className="navbar-category-item text-center border-right">
            <span
              onClick={() => {
                setIsExpandCategory(prevState => !prevState)
              }}
              className="cursor-pointer"
            >
              Category <i className="mdi mdi-menu-down mdi-18px" />
            </span>
          </div>
          {categories.slice(0, 5).map((c) => (
            <div className="navbar-category-item text-center" key={c._id}>
              <div onClick={() => navigate(`/browse?categoryid=${c._id}&categorytitle=${c.title}`)}>
                <Link to="#" className="no-underline">
                  {c.title}
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div
          id="navbar-category-dropdown"
          className={`${isExpandCategory && "expand"} d-flex`}
        >
          <div className="container my-auto">
            <h2 className="asap-headline text-philadelphia-blue">Categories</h2>
            <div className="row">
              {categories.map(c => (
                <div
                  className="col-md-3 my-3"
                  key={c._id}
                  onClick={() => {
                    navigate(`/browse?categoryid=${c._id}&categorytitle=${c.title}`);
                    setIsExpandCategory(prevState => !prevState);
                  }}>
                  <Link to="#" className="no-underline">
                    {c.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
