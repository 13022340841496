import { httpClient as client, getToken } from "./client";

export function getThreadCategory() {
  return new Promise((resolve, reject) => {
    client
      .get(`thread/category`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}