import React, { useState, useEffect } from "react"
import "./Footer.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { getThreadCategory } from "@repository/ThreadCategoryRepository"
import IcLogo from "@icons/cerebro-ic.png"
import Partner1 from "@backgrounds/partner-1.png"
import Partner2 from "@backgrounds/partner-2.png"
import { navigate } from "gatsby"

function Footer() {
  const [popularCategories, setPopularCategories] = useState([])

  useEffect(() => {
    handleGetCategories()
  }, [])

  const handleGetCategories = () => {
    getThreadCategory()
      .then(res => {
        setPopularCategories(res.data.data)
      })
      .catch(err => {
        console.log(err.response)
      })
  }
  return (
    <footer className="bg-pearl-gray mt-auto">
      <div className="container py-4">
        <div className="row d-flex flex-column align-items-center">
          {/* <div className="col-xl-5 col-lg-4 col-md-5 order-md-1 order-2"> */}
          <div className="col-xl-8 d-flex flex-column align-items-center mb-3">
            <img src={IcLogo} alt="Cerebro" className="mb-2" />
            {/* <div className="asap-headline mb-1 text-philadelphia-blue">Cerebro Group</div>
            <div className="asap-body text-iron-gray">
              Cerebro street address no.30<br />
              Full detail of the street<br />
              Medan, North Sumatra<br />
              <b>INDONESIA</b>
            </div> */}
          </div>
          {/* <div className="col-xl-7 col-lg-8 col-md-7 mb-md-0 mb-3 order-md-2 order-1"> */}
          <div className="col-xl-7">
            {/* <div className="asap-body font-weight-bold mb-2">Navigation</div> */}
            <div className="row mb-3">
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-2 d-flex p-0">
                <div
                  className="asap-body text-iron-gray cursor-pointer menu-text flex-grow-1 text-center"
                  onClick={() => navigate("/about")}
                >
                  About Us
                </div>
                <div className="text-harbor-gray">|</div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-2 d-flex p-0">
                <div
                  className="asap-body text-iron-gray cursor-pointer menu-text flex-grow-1 text-center"
                  onClick={() => navigate("/about#about-contact")}
                >
                  Contact Us
                </div>
                <div className="text-harbor-gray d-none d-lg-block">|</div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-2 d-flex p-0">
                <div className="asap-body text-iron-gray cursor-pointer menu-text flex-grow-1 text-center">
                  Terms of Service
                </div>
                <div className="text-harbor-gray">|</div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-2 d-flex p-0">
                <div className="asap-body text-iron-gray cursor-pointer menu-text flex-grow-1 text-center">
                  Privacy Policy
                </div>
              </div>
            </div>
            {/* {popularCategories.length > 0 && (
              <>
                <div className="asap-body font-weight-bold mb-2">Most Popular Categories</div>
                <div className="row">
                  {popularCategories.map((popularCategory) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-4 col-6 mb-2 cursor-pointer"
                      key={popularCategory._id}
                      onClick={() => navigate(`/browse?categoryid=${popularCategory._id}&categorytitle=${popularCategory.title}`)}>
                      <div className="asap-body text-iron-gray menu-text">
                        {popularCategory.title}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>
      <div className="bg-smoke-gray py-3">
        <div className="container d-flex flex-column align-items-center">
          <div className="asap-body font-weight-bold mb-3">Partnership</div>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <img src={Partner1} className="img-partner mr-3" />
            <img src={Partner2} className="img-partner-2" />
          </div>
          <div className="asap-body text-iron-gray order-md-1 order-2">
            © 2021 Cerebro
          </div>
        </div>
        {/* <div className="container d-flex flex-md-row flex-column justify-content-between align-items-md-center">
          <div className="asap-body text-iron-gray order-md-1 order-2">
            © 2021 Cerebro
          </div>
          <div className="d-flex align-items-center order-md-2 order-1 mb-md-0 mb-2">
            <a href="https://www.facebook.com/medstudentnetwork" target="_blank">
              <div className="bg-abalone-gray rounded-circle d-flex align-items-center justify-content-center icon cursor-pointer">
                <FontAwesomeIcon icon={faFacebookF} className="text-pure-white" />
              </div>
            </a>
            <a href="https://twitter.com/medstudnetwork" target="_blank">
              <div className="bg-abalone-gray rounded-circle d-flex align-items-center justify-content-center mx-2 icon cursor-pointer">
                <FontAwesomeIcon icon={faTwitter} className="text-pure-white" />
              </div>
            </a>
            <a href="https://www.instagram.com/cerebro.pro/" target="_blank">
              <div className="bg-abalone-gray rounded-circle d-flex align-items-center justify-content-center mr-2 icon cursor-pointer">
                <FontAwesomeIcon icon={faInstagram} className="text-pure-white" />
              </div>
            </a>
            <a href="https://www.youtube.com/channel/UCwB4c8l99GEpZH2YczH10Kg" target="_blank">
              <div className="bg-abalone-gray rounded-circle d-flex align-items-center justify-content-center icon cursor-pointer">
                <FontAwesomeIcon icon={faYoutube} className="text-pure-white" />
              </div>
            </a>
          </div>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
