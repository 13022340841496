import { httpClient as client, getToken } from "./client";

export function getNotifications(countryCode, threadCategoryId) {
  return new Promise((resolve, reject) => {
    client
      .get(`notifications`, {
        headers: {Authorization: `Bearer ${getToken()}`},
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}
