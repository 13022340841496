import axios from "axios"
import { navigate } from "gatsby"

/** Default config for axios instance */
let config = {
  baseURL: process.env.BASE_URL,
}

/** Creating the instance for axios */
const httpClient = axios.create(config)

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => {
    /** TODO: Add any response interceptors */
    return response
  },
  error => {
    /** TODO: Do something with response error */
    const errorResponse = error.response
    console.log(error);
    if (isUnauthenticated(errorResponse)) {
      removeToken()
      navigate("/login")
    }
    return Promise.reject(error)
  }
)

const isUnauthenticated = error => {
  if (error.status === 401) {
    return true
  }

  return false
}

const getToken = () => typeof window !== 'undefined' && localStorage.getItem("access_token")

const storeToken = token => typeof window !== 'undefined' && localStorage.setItem("access_token", token)

const removeToken = () => typeof window !== 'undefined' && localStorage.removeItem("access_token")

const removeCountry = () => typeof window !== 'undefined' && localStorage.removeItem("country")

export { httpClient, getToken, storeToken, removeToken, removeCountry }
